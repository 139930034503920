import camelCase from 'lodash/camelCase'
import transform from 'lodash/transform'

type APIResponseValue<T> = object | number | string | Array<T>

/**
 * Takes an object from an api response and recursively
 * cleans up the properties to match correct camel
 * casing from different casing from the backend
 * @param {Object} item
 * @returns newData
 */
function sanitizeProperties<T>(item: APIResponseValue<T>): APIResponseValue<T> {
  if (item === null || typeof item !== 'object') {
    return item
  } else {
    return transform(
      item,
      (
        result: {[key: string]: APIResponseValue<T>},
        value: APIResponseValue<T>,
        key: string,
      ) => {
        result[camelCase(key)] = sanitizeProperties(value)
      },
    )
  }
}

const getDirtyFields = <
  TData extends Record<keyof TDirtyItems, unknown>,
  TDirtyItems extends Record<string, unknown>,
>(
  formValues: TData,
  dirtyItems: TDirtyItems,
): Partial<TData> => {
  return Object.entries(dirtyItems).reduce((dirtyData, [key, value]) => {
    if (value === false) return dirtyData
    if (value === true) return {...dirtyData, [key]: true}

    const child = getDirtyFields(
      formValues[key] as TData,
      dirtyItems[key] as TDirtyItems,
    )

    if (typeof child === 'object' && Object.keys(child).length === 0) {
      return dirtyData
    }

    if (Array.isArray(child) && child.length === 0) {
      return dirtyData
    }

    return {
      ...dirtyData,
      [key]: child,
    }
  }, {})
}

export {sanitizeProperties, getDirtyFields}
