'use client'
// Note: User pref must be used with the client session token to get the user from the token
import {API_BASE_URL, APIKEY} from '~data/constants.ts'

export const getUserPreference = async <T>(
  userPrefKey: string,
  token: string,
): Promise<T | null> => {
  try {
    // First attempt to get from local storage
    if (typeof window !== 'undefined') {
      const savedPref = localStorage.getItem(`user-pref.${userPrefKey}`)
      if (savedPref) {
        if (savedPref === 'None') {
          return null
        }
        try {
          return JSON.parse(savedPref)
        } catch (err) {
          if (typeof savedPref === 'string') {
            // allow for non-JSON string values
            return savedPref as string as T
          }
        }
      }
    }
    const query = await fetch(
      `${API_BASE_URL}ffe/user-preference/${userPrefKey}/`,
      {
        headers: {
          apikey: `${APIKEY}`,
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const textResponse = await query.text()
    localStorage.setItem(`user-pref.${userPrefKey}`, textResponse)

    // "None" response happens if the user has not set a preference yet
    if (query.status !== 200 || textResponse === 'None') {
      return null
    }

    return JSON.parse(textResponse)
  } catch (err) {
    console.error(err)
    return null
  }
}

export const postUserPreference = async (
  key: string,
  value: string,
  token: string,
): Promise<boolean> => {
  try {
    const data = new URLSearchParams()
    data.append('key', key)
    data.append('value', value)
    if (typeof window !== 'undefined') {
      if (key && value) {
        localStorage.setItem(`user-pref.${key}`, value)
      }
    }
    const query = await fetch(`${API_BASE_URL}ffe/user-preference/`, {
      method: 'POST',
      headers: {
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    })
    return query.status === 204
  } catch (err) {
    console.error(err)
    return false
  }
}

// Clean up local storage by removing all user preferences except the ones in keysToKeep
export const cleanUpLocalStorage = (
  keysToKeep: string[],
  targetPrefix: string,
) => {
  // Iterate through all the keys in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)

    if (key?.startsWith(targetPrefix)) {
      const uuid = key.replace(targetPrefix, '')

      if (!keysToKeep.includes(uuid)) {
        localStorage.removeItem(key)
      }
    }
  }
}
