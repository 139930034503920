import {
  fetchHoldings,
  fetchWatchlistHoldings,
  getMissingTransactions,
  getMyStocksPortfolios,
  getMyStocksTransactions,
} from './thunks'
import {MyStocksState} from '~app/(main)/my-stocks/my-stocks.slice'
import type {ActionReducerMapBuilder, PayloadAction} from '@reduxjs/toolkit'
import type {
  Holding,
  PortfolioSummary,
  PortfolioUuidType,
  WatchlistHolding,
} from '~types/my-stocks'
import {ALTERNATE_ACTIVE_PORTFOLIO_VIEW, STATUS_STATES} from '~data/constants'
import {updateSubnavPortfolios} from '~app/(main)/my-stocks/my-stocks.slice'

export default (builder: ActionReducerMapBuilder<MyStocksState>) => {
  builder

    .addCase(getMyStocksPortfolios.pending, (state: MyStocksState) => {
      // Only set loading if we need to update subnav portfolios
      if (state.subnavPortfolios.length === 0) {
        state.getPortfolioStatus = STATUS_STATES.LOADING
      }
    })
    .addCase(getMyStocksPortfolios.fulfilled, (state: MyStocksState) => {
      // Only set to succeeded if we actually updated the subnav portfolios
      if (state.subnavPortfolios.length > 0) {
        state.getPortfolioStatus = STATUS_STATES.SUCCEEDED
      }
    })
    .addCase(updateSubnavPortfolios, (state: MyStocksState) => {
      // Set to succeeded when portfolios are actually updated
      state.getPortfolioStatus = STATUS_STATES.SUCCEEDED
    })
    .addCase(getMyStocksPortfolios.rejected, (state: MyStocksState) => {
      state.getPortfolioStatus = STATUS_STATES.FAILED
    })

    .addCase(getMyStocksTransactions.pending, (state: MyStocksState) => {
      if (
        state.activePortfolioUuid &&
        !state.transactionsLookup?.[state.activePortfolioUuid]?.transactions
      ) {
        state.getTransactionStatus = STATUS_STATES.LOADING
      }
    })
    .addCase(getMyStocksTransactions.fulfilled, (state: MyStocksState) => {
      if (
        state.activePortfolioUuid &&
        state.transactionsLookup?.[state.activePortfolioUuid]?.transactions
      ) {
        state.getTransactionStatus = STATUS_STATES.SUCCEEDED
      }
    })

    // getMissingTransactions query
    .addCase(getMissingTransactions.pending, (state: MyStocksState) => {
      state.getMissingTransactionsStatus = STATUS_STATES.LOADING
    })
    .addCase(getMissingTransactions.fulfilled, (state: MyStocksState) => {
      state.getMissingTransactionsStatus = STATUS_STATES.SUCCEEDED
    })
    .addCase(getMissingTransactions.rejected, (state: MyStocksState) => {
      state.getMissingTransactionsStatus = STATUS_STATES.FAILED
    })

    // fetchHoldings graphql query
    .addCase(fetchHoldings.pending, (state: MyStocksState) => {
      state.getHoldingsStatus = STATUS_STATES.LOADING
    })
    .addCase(
      fetchHoldings.fulfilled,
      (
        state: MyStocksState,
        action: PayloadAction<{
          portfolioUuid: PortfolioUuidType
          holdings: Holding[]
          activeHoldings: Holding[] | null
          summary: PortfolioSummary
          activeSummary: PortfolioSummary | null
        }>,
      ) => {
        state.getHoldingsStatus = STATUS_STATES.SUCCEEDED
        if (action.payload.activeHoldings)
          state.holdings = action.payload.activeHoldings
        if (!action.payload.holdings || action.payload.holdings.length === 0) {
          state.holdingsLookup = Object.fromEntries(
            Object.entries(state.holdingsLookup).filter(
              ([key]) => key !== action.payload.portfolioUuid,
            ),
          )
        } else {
          if (
            action.payload.portfolioUuid ==
            ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks
          ) {
            state.holdingsLookup[
              ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllPortfolios as string
            ] = action.payload.holdings
          }
          if (
            action.payload.portfolioUuid ==
            ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllPortfolios
          ) {
            state.holdingsLookup[
              ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks as string
            ] = action.payload.holdings
          }
          state.holdingsLookup[action.payload.portfolioUuid as string] =
            action.payload.holdings
        }
        if (action.payload.activeSummary)
          state.activePortfolioSummary = action.payload.activeSummary

        // Update the portfolio summary lookup
        state.portfolioSummaryLookup[action.payload.portfolioUuid as string] =
          action.payload.summary

        // Also update the all-portfolios summary to match all-stocks
        // TEMPORARY SOLUTION until we straighten out the portfolioUuids
        // to not also reflect All Stocks and All Portfolios
        if (
          action.payload.portfolioUuid ===
          ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks
        ) {
          state.portfolioSummaryLookup[
            ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllPortfolios
          ] = action.payload.summary
        }
      },
    )
    .addCase(fetchHoldings.rejected, (state: MyStocksState) => {
      state.getHoldingsStatus = STATUS_STATES.FAILED
      // TODO: Handle error
      // state.error = action.payload ?? 'Unknown error occurred'
    })

    // fetchWatchlistHoldings graphql query
    .addCase(fetchWatchlistHoldings.pending, (state: MyStocksState) => {
      state.getWatchlistHoldingsStatus = STATUS_STATES.LOADING
    })
    .addCase(
      fetchWatchlistHoldings.fulfilled,
      (
        state: MyStocksState,
        action: PayloadAction<{
          portfolioUuid: PortfolioUuidType
          watchlistHoldings: WatchlistHolding[]
          activeWatchlistHoldings: WatchlistHolding[] | null
        }>,
      ) => {
        state.getWatchlistHoldingsStatus = STATUS_STATES.SUCCEEDED
        if (action.payload.activeWatchlistHoldings)
          state.watchlistHoldings = action.payload.activeWatchlistHoldings

        // Remove the portfolio from the lookup if there are no holdings
        // TODO: Review do we actually want to save an empty array of holdings if a portfolio is empty?
        if (
          !action.payload.watchlistHoldings ||
          action.payload.watchlistHoldings.length === 0
        ) {
          state.watchlistHoldingsLookup = Object.fromEntries(
            Object.entries(state.watchlistHoldingsLookup).filter(
              ([key]) => key !== action.payload.portfolioUuid,
            ),
          )
        } else {
          state.watchlistHoldingsLookup[
            action.payload.portfolioUuid as string
          ] = action.payload.watchlistHoldings
        }
      },
    )
    .addCase(fetchWatchlistHoldings.rejected, (state: MyStocksState) => {
      state.getWatchlistHoldingsStatus = STATUS_STATES.FAILED
    })
}
