import {
  VisaIcon,
  MasterCardIcon,
  DiscoverIcon,
  AmexIcon,
} from '~components/jester-temp/icons'

export const API_BASE_URL = process.env['NEXT_PUBLIC_API_BASE_URL']
export const APIKEY = process.env['NEXT_PUBLIC_APIKEY']
export const BRAZE_API_KEY = process.env['NEXT_PUBLIC_BRAZE_API_KEY']
export const BRAZE_BASE_URL = process.env['NEXT_PUBLIC_BRAZE_BASE_URL']
export const NEXT_PUBLIC_PREMIUM_GRAPHQL_API_URL =
  process.env['NEXT_PUBLIC_PREMIUM_GRAPHQL_API_URL']
export const NEXT_PUBLIC_APIKEY = process.env['NEXT_PUBLIC_APIKEY']
export const NEXT_PUBLIC_API_BASE_URL = process.env['NEXT_PUBLIC_API_BASE_URL']
export const NEXT_PUBLIC_USERSNAP_SPACE_API_KEY =
  process.env['NEXT_PUBLIC_USERSNAP_SPACE_API_KEY']
export const ENVIRONMENT = process.env['NODE_ENV']
export const SEGMENT_PROJECT = process.env['SEGMENT_PROJECT']
export const SEGMENT_KEY = process.env['SEGMENT_KEY']
export const NEXT_PUBLIC_EMPLOYEE_ONLY_USERSNAP_SPACE_API_KEY =
  process.env['NEXT_PUBLIC_EMPLOYEE_ONLY_USERSNAP_SPACE_API_KEY']
export const NEXT_PUBLIC_FOOL_ENV = process.env['NEXT_PUBLIC_FOOL_ENV']
export const NEXT_PUBLIC_IS_PREVIEW = process.env['NEXT_PUBLIC_IS_PREVIEW']
export const TAGS_MANIFEST_PREFIX = 'PREMIUM_EPIC_TAGS_MANIFEST'

export const NEXT_PUBLIC_QUOTES_ENDPOINT = `${API_BASE_URL}quotes/v4/`
export const QUOTES_REALTIME_ENDPOINT = `${API_BASE_URL}quotes/realtime/`

export const MY_STOCKS_SLUG = 'my-stocks'
export const MY_STOCK_DEFAULTPAGE = `/${MY_STOCKS_SLUG}`
export const MY_STOCK_STOCKSPAGE = `/${MY_STOCKS_SLUG}/stocks`
export const MY_STOCK_PORTFOLIOSPAGE = `/${MY_STOCKS_SLUG}/portfolios`
export const MY_STOCKS_NAVITEM_ID = 'my-stocks-nav-item'

export const US_EXCHANGES = [
  'NYSE',
  'NASDAQ',
  'NASDAQOTH',
  'NYSEMKT',
  'NASDAQMUTFUND',
]

// Toast Notifications
export const TOAST_NOTIFICATION_DURATION = 5000

// Payment Details Form Types
export enum PAYMENT_DETAILS_FORM_TYPE {
  AddPaymentCard = 'AddPaymentCard',
  BillingAddress = 'BillingAddress',
  InfoStateCAandOthers = 'InfoStateCAandOthers',
  StateCertification = 'StateCertification',
}

export enum CC_ISSUER_TYPE {
  Visa = 'visa',
  MasterCard = 'mastercard',
  AmericanExpress = 'american-express',
  Discover = 'discover',
}

interface ICardDetails {
  name: string
  icon: () => JSX.Element
}
// Payment Cards Issuers
export const PAYMENT_CARD_SUPPORTED: Record<CC_ISSUER_TYPE, ICardDetails> = {
  [CC_ISSUER_TYPE.Visa]: {
    name: 'Visa',
    icon: VisaIcon,
  },
  [CC_ISSUER_TYPE.MasterCard]: {
    name: 'Master Card',
    icon: MasterCardIcon,
  },
  [CC_ISSUER_TYPE.AmericanExpress]: {
    name: 'American Express',
    icon: AmexIcon,
  },
  [CC_ISSUER_TYPE.Discover]: {
    name: 'Discover',
    icon: DiscoverIcon,
  },
}

// My Stocks Forms
export enum MY_STOCKS_FORM_TYPE {
  AddPortfolio = 'AddPortfolio',
  EditPortfolio = 'EditPortfolio',
  AddStocks = 'AddStocks',
  UploadCSV = 'UploadCSV',
  AddTransactions = 'AddTransactions',
  EditTransactions = 'EditTransactions',
  RemoveHolding = 'RemoveHoldings',
  AddPurchaseTransactions = 'AddPurchaseTransactions',
  RemoveTransaction = 'RemoveTransaction',
}

// Account Forms
export enum ACCOUNT_FORM_TYPE {
  UpdateSmsPreferences = 'UpdateSmsPreferences',
  RemovePhoneNumber = 'RemovePhoneNumber',
}

// My Stocks Transaction Types
export const MY_STOCKS_TRANSACTION_TYPES = [
  {
    key: 'buy',
    label: 'Buy',
  },
  {
    key: 'sell',
    label: 'Sell',
  },
]

// Used for changing the view of the active portfolios
// that is not tied to a specific portfolio
export enum ALTERNATE_ACTIVE_PORTFOLIO_VIEW {
  NewPortfolio = 'new-portfolio',
  AllPortfolios = 'all-portfolios',
  AllStocks = 'all-stocks',
}

export enum STATUS_STATES {
  INITIAL = 'initial',
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

// My Stocks Redux Store Key
export const MY_STOCKS_REDUCER_KEY = 'myStocks'

export const MY_STOCKS_USER_PREF_TABLE_KEY = 'table-my-stocks-'

// GamePlan (for Content, Advisor Notes, Widgets, etc. queries)
export const FOOL_PREMIUM = 4056 // L1 Content
export const EPIC_BUNDLE = 4299 // L2+ Content
export const L1_GAMEPLAN_PRODUCTS = [FOOL_PREMIUM]
export const L2_PLUS_GAMEPLAN_PRODUCTS =
  L1_GAMEPLAN_PRODUCTS.concat(EPIC_BUNDLE)
