import {ApolloClient, gql} from '@apollo/client'
import {APIKEY, NEXT_PUBLIC_QUOTES_ENDPOINT} from '~data/constants'
import type {Holding, RealtimeQuotesResponse} from '~types/my-stocks'
import type {Instrument} from '~types/__generated__/graphql'
import {Session} from 'next-auth/types'

export type MinimalInstrument = {
  instrumentFound: boolean
  instrumentId: number
  symbol: string
  exchange: string
}

export type MinimalQuote = {
  instrumentId: number
  symbol: string
  exchange: string
  currencyCode: string
  currentPrice: number | undefined
  percentChange: number | undefined
  lastTradeDate: string | null
}

type InstrumentByIdJson = {
  Id: number
  Symbol: string
  Exchange: string
}

type InstrumentByExchangeSymbolJson = {
  InstrumentFound: boolean
  Instrument: {
    Id: number
  }
  Symbol: string
  Exchange: string
}

type QuoteByIdJson = {
  InstrumentId: number
  Symbol: string
  Exchange: string
  CurrencyCode: string
  CurrentPrice?: {
    Amount: number
  }
  PercentChange?: {
    Value: number
  }
  LastTradeDate?: string
}

const notFoundInstrument: MinimalInstrument = {
  instrumentFound: false,
  instrumentId: 0,
  symbol: '',
  exchange: '',
}

interface QuoteInstrument {
  InstrumentId: string
  CurrencyCode: string
  CurrentPrice?: {
    Amount: number
  }
  Change?: {
    Amount: number
  }
  PercentChange?: {
    Value: number
  }
  Exchange: string
  Beta5Y: number
  FiftyTwoWeekRange: {
    Minimum: {
      Amount: number
    }
  }
  MarketCap: {
    Amount: number
  }
}

const INSTRUMENT_DATA_QUERY = gql`
  query MyStocksInstrumentData($instrumentIds: [ID]) {
    instruments(ids: $instrumentIds) {
      instrumentId
      accessibleFoolRecommendations {
        id
      }
      quote {
        revenueGrowth
      }
      predictionByInstrument {
        returnEstimateHigh
        returnEstimateLow
        maxDrawdownRiskPerYear
        cmaTagLabel
      }
    }
  }
`

export const getQuotesInBatch = async (
  batches: string[],
  revalidate = 0,
  session?: Session | null,
) => {
  const requests = batches.map((instrumentIds) =>
    fetch(`${NEXT_PUBLIC_QUOTES_ENDPOINT}quotes/${instrumentIds}`, {
      next: {revalidate},
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        apikey: `${APIKEY}`,
        ...(session?.accessToken
          ? {Authorization: `Bearer ${session.accessToken}`}
          : {}),
      },
    }).then((response) => response.json()),
  )
  try {
    return await Promise.allSettled(requests)
  } catch (e) {
    console.error('requests:', e)
  }
}

export const getQuotesData = async (
  batches: string[],
  session?: Session | null,
): Promise<RealtimeQuotesResponse> => {
  const settledResults: PromiseSettledResult<QuoteInstrument[]>[] | undefined =
    await getQuotesInBatch(batches, 0, session)

  const response: QuoteInstrument[] = settledResults
    ? settledResults
        .filter((result) => result.status === 'fulfilled')
        .map(
          (result) =>
            (result as PromiseFulfilledResult<QuoteInstrument[]>).value,
        )
        .flat()
    : []

  const timestamp = new Date().getTime()
  const quotes = response.reduce((acc, quote) => {
    const instrument = {
      [quote?.InstrumentId]: {
        instrumentId: quote?.InstrumentId || '',
        code: quote?.CurrencyCode || '',
        price: quote?.CurrentPrice?.Amount,
        priceChange: quote?.Change?.Amount,
        change: quote?.PercentChange?.Value
          ? Math.round((quote?.PercentChange?.Value || 0) * 100 * 100) / 100
          : null,
        exchange: quote?.Exchange || '',
        beta5Y: quote?.Beta5Y,
        fiftyTwoWeekRange: quote?.FiftyTwoWeekRange?.Minimum?.Amount,
        marketCap: quote?.MarketCap?.Amount,
        timestamp,
      },
    }
    return {...acc, ...instrument}
  }, {})
  return quotes
}

export const getQuoteByInstrumentId = async (
  instrumentId: number,
): Promise<MinimalQuote> => {
  const url = `${NEXT_PUBLIC_QUOTES_ENDPOINT}quotes/${instrumentId}?apikey=${APIKEY}`
  try {
    const response = await fetch(url, {
      next: {
        tags: [
          'getQuoteByInstrumentId',
          `getQuoteByInstrumentId:${instrumentId}`,
        ],
      },
    })
    const quoteData: QuoteByIdJson = await response.json()
    return {
      instrumentId: quoteData.InstrumentId,
      symbol: quoteData.Symbol,
      exchange: quoteData.Exchange,
      currencyCode: quoteData.CurrencyCode,
      currentPrice: quoteData.CurrentPrice?.Amount,
      percentChange: quoteData.PercentChange?.Value,
      lastTradeDate: quoteData.LastTradeDate || null,
    }
  } catch (e) {
    return {
      instrumentId: instrumentId,
      symbol: '',
      exchange: '',
      currencyCode: '',
      currentPrice: undefined,
      percentChange: undefined,
      lastTradeDate: null,
    }
  }
}

export const getInstrumentById = async (
  instrumentId: number,
): Promise<MinimalInstrument> => {
  const url = `${NEXT_PUBLIC_QUOTES_ENDPOINT}instruments/${instrumentId}?apikey=${APIKEY}`
  try {
    const response = await fetch(url, {
      next: {
        tags: ['getInstrumentById', `getInstrumentById:${instrumentId}`],
      },
    })
    const instrumentData: InstrumentByIdJson = await response.json()
    return {
      instrumentFound: true,
      instrumentId: instrumentData.Id,
      symbol: instrumentData.Symbol,
      exchange: instrumentData.Exchange,
    }
  } catch (e) {
    return notFoundInstrument
  }
}

export const getInstrumentByExchangeAndSymbol = async (
  exchange: string,
  symbol: string,
): Promise<MinimalInstrument> => {
  const url = `${NEXT_PUBLIC_QUOTES_ENDPOINT}Instrument/FindInstrumentBySymbol?symbol=${exchange}:${symbol}&apikey=${APIKEY}`
  try {
    const response = await fetch(url)
    const instrumentData: InstrumentByExchangeSymbolJson = await response.json()
    return {
      instrumentFound: instrumentData.InstrumentFound,
      instrumentId: instrumentData.Instrument.Id,
      symbol: instrumentData.Symbol,
      exchange: instrumentData.Exchange,
    }
  } catch (e) {
    return notFoundInstrument
  }
}

export const getInstrumentGQLData = async (
  holdings: Holding[],
  apolloClient: ApolloClient<object>,
) => {
  const holdingIds = holdings?.map((holding) => {
    return holding.instrumentId
  })

  const splitNumberArray = (largeArray: number[], batchSize = 500) =>
    Array.from({length: Math.ceil(largeArray.length / batchSize)}, (_, i) =>
      largeArray.slice(i * batchSize, (i + 1) * batchSize),
    )

  const holdingsBatches = splitNumberArray(holdingIds)

  let allGQLInstruments: Instrument[] = []

  const queryPromises = holdingsBatches.map((batch) =>
    apolloClient
      .query({
        query: INSTRUMENT_DATA_QUERY,
        variables: {
          instrumentIds: batch,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({data}) => {
        return data.instruments
      }),
  )

  try {
    const results = await Promise.all(queryPromises)
    allGQLInstruments = results.flat()
  } catch (error) {
    console.error('Error fetching data:', error)
  }

  let newHoldings = holdings

  if (holdings && allGQLInstruments) {
    // Create an instrument mapping for faster finding
    const instrumentMapping = allGQLInstruments.reduce(
      (acc: {[key: number]: Instrument}, item: Instrument) => {
        acc[item.instrumentId] = item
        return acc
      },
      {},
    )

    newHoldings = holdings.map((holding: Holding) => {
      const instrument = instrumentMapping[holding.instrumentId]
      const estimations = instrument?.predictionByInstrument
      return {
        ...holding,
        numOfRecs: instrument?.accessibleFoolRecommendations?.length || 0,
        revenueGrowth: instrument?.quote?.revenueGrowth,
        projectedReturns: estimations
          ? [estimations.returnEstimateLow, estimations.returnEstimateHigh]
          : null,
        estimatedDrawdown: estimations?.maxDrawdownRiskPerYear,
        cmaTagLabel: estimations?.cmaTagLabel,
      }
    })
  }
  return newHoldings
}
