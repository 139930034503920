import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {STATUS_STATES} from '~data/constants'
import type {WatchState, WatchStateType} from '~types/my-stocks'
import extraReducers from './watches/extraReducers'

const initialState: WatchStateType = {
  watches: [],
  showFollowModal: false,
  modalWatch: undefined,
  accountIds: [],
  watchesState: STATUS_STATES.INITIAL,
  hasLoaded: false,
}

const watchesSlice = createSlice({
  name: 'watches',
  initialState,
  reducers: {
    setWatches: (state, action: PayloadAction<{watches: WatchState[]}>) => {
      state.watches = action.payload.watches
      state.hasLoaded = true
    },
    setAccounts: (state, action: PayloadAction<{accounts: string[]}>) => {
      state.accountIds = action.payload.accounts
    },
    setFollowModal: (
      state,
      action: PayloadAction<{showModal: boolean; modalWatch?: WatchState}>,
    ) => {
      state.showFollowModal = action.payload.showModal
      state.modalWatch = action.payload.modalWatch
    },
  },
  extraReducers,
})

export const {setWatches, setAccounts, setFollowModal} = watchesSlice.actions
export default watchesSlice.reducer
