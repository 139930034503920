import type {WatchStateType} from '~types/my-stocks'
import type {ActionReducerMapBuilder} from '@reduxjs/toolkit'
import {STATUS_STATES} from '~data/constants'
import {fetchAndSetWatches} from '~app/(main)/my-stocks/utils/thunks'

export default (builder: ActionReducerMapBuilder<WatchStateType>) => {
  builder
    // fetchAndSetWatches thunk
    .addCase(fetchAndSetWatches.pending, (state: WatchStateType) => {
      state.watchesState = STATUS_STATES.LOADING
    })
    .addCase(fetchAndSetWatches.fulfilled, (state: WatchStateType) => {
      state.watchesState = STATUS_STATES.SUCCEEDED
    })
    .addCase(fetchAndSetWatches.rejected, (state: WatchStateType) => {
      state.watchesState = STATUS_STATES.FAILED
    })
}
