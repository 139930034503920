// import {auth} from '~auth/index'
import type {RealtimeQuotes} from '~types/my-stocks'
import {GetTransactionPayload} from '~types/my-stocks'
import {
  APIKEY,
  NEXT_PUBLIC_API_BASE_URL,
  QUOTES_REALTIME_ENDPOINT,
} from '~data/constants'
import {AppDispatch} from '~data/client/store'
import {Session} from 'next-auth/types'

const DEFAULT_ISO_CURRENCY_CODE = 'USD'

const fetchRealtimeQuotes = async (
  queryString: string,
  session?: Session | null,
): Promise<RealtimeQuotes> => {
  const url = `${QUOTES_REALTIME_ENDPOINT}${queryString}`
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        ...(session?.accessToken
          ? {Authorization: `Bearer ${session.accessToken}`}
          : {}),
      },
    }).catch((error) => {
      throw `Error fetching realtime quotes: ${error}`
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(`Error making realtime quotes API call: ${error}`)
    throw error
  }
}

// const getWatchedInstrumentsApi = async () => {
//   const session = await auth()
//   const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/watchlists/get_watched_instruments/`
//   let response
//   try {
//     response = await fetch(api, {
//       next: {revalidate: 0},
//       headers: {
//         'Content-Type': 'application/json',
//         apikey: `${APIKEY}`,
//         Authorization: `Bearer ${session?.accessToken}`,
//       },
//     })
//     return await response.json()
//   } catch (error) {
//     // handle error
//   }
// }

const getMyStocksAccountsApi = async (token: string) => {
  const accountsApi = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/portfolios/get_accounts/`
  try {
    const getAccountsData = await fetch(accountsApi, {
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
    })
    return getAccountsData.json()
  } catch (error) {
    throw 'There was an error fetching accounts in My Stocks'
  }
}

const getMyStocksPortfoliosApi = async (token: string) => {
  // TODO: Merge this endpoint and `portfolios/get_accounts` data points into a single api endpoint or add to graphQL
  const summaryApi = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/portfolios/get_portfolio_summaries/`
  try {
    const getSummaryData = await fetch(summaryApi, {
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
    })
    const summaryData = await getSummaryData.json()
    return {summaryData}
  } catch (error) {
    throw 'There was an error fetching portfolios in My Stocks'
  }
}

const getMyStocksPlaceholderHoldingsApi = async (token: string) => {
  const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/manual_accounts/get_placeholder_holdings/`
  try {
    const response = await fetch(api, {
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await response.json()
    return data
  } catch (error) {
    throw 'There was an error getting placeholder holdings in My Stocks'
  }
}

const createMyStocksAccountApi = async (
  token: string,
  name: string,
  isoCurrencyCode?: string | null,
) => {
  const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/manual_accounts/create_account/`
  try {
    const newAccountData = await fetch(api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name,
        iso_currency_code: isoCurrencyCode || DEFAULT_ISO_CURRENCY_CODE,
      }),
    })
    if (newAccountData) return await newAccountData.json()
  } catch (error) {
    throw 'There was an error creating a new portfolio in My Stocks'
  }
}

const updateMyStocksAccountApi = async (
  token: string,
  accountId: number,
  name: string,
) => {
  const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/manual_accounts/update_account/`
  try {
    const updatePortfolioData = await fetch(api, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        account_id: accountId,
        name,
      }),
    })
    if (updatePortfolioData) return await updatePortfolioData.json()
  } catch (error) {
    throw 'There was an error editing a portfolio in My Stocks'
  }
}

// Returns an arbitrary number on success
const deleteMyStocksInstitutionAccountApi = async (
  token: string,
  accountId: string,
): Promise<number | undefined> => {
  const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/delete_institution_links/`
  try {
    const deletePortfolioData = await fetch(api, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids: [accountId],
      }),
    })
    if (deletePortfolioData) return await deletePortfolioData.json()
  } catch (error) {
    throw 'There was an error editing a portfolio in My Stocks'
  }
}

// Returns an arbitrary number on success
const deleteMyStocksManualAccountApi = async (
  token: string,
  accountId: string,
) => {
  const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/portfolios/delete_accounts/`
  try {
    const deletePortfolioData = await fetch(api, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids: [accountId],
      }),
    })
    if (deletePortfolioData) return await deletePortfolioData.json()
  } catch (error) {
    throw 'There was an error editing a portfolio in My Stocks'
  }
}

const uploadMyStocksCSVApi = async ({
  token,
  file,
  institution,
  accountId,
}: {
  token: string
  file: File
  institution: string
  accountId: number
}) => {
  const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/manual_accounts/import_holdings_csv/`
  const data = new FormData()
  data.append('file', file)
  data.append('institution', institution)
  data.append('account_id', `${accountId}`)
  try {
    const uploadCSVData = await fetch(api, {
      method: 'POST',
      headers: {
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
      body: data,
    })
    if (uploadCSVData) return await uploadCSVData.json()
  } catch (error) {
    throw 'There was an error uploading a CSV file in My Stocks'
  }
}

const getMyStocksTransactionsApi = async ({
  token,
  portfolioUuids,
  instrumentIds,
  type,
  dateFrom,
  dateTo,
  sort,
  excludePlaceholder,
  pageSize,
  page,
}: {
  token: string
  portfolioUuids?: string[]
  instrumentIds?: number[]
  type?: string
  dateFrom?: string
  dateTo?: string
  sort?: string
  excludePlaceholder?: boolean
  pageSize?: number
  page?: number
  dispatch: AppDispatch
}) => {
  let transactionData
  try {
    const transformedPayload: GetTransactionPayload = {}
    if (excludePlaceholder)
      transformedPayload['exclude_placeholder'] = `${excludePlaceholder}`
    if (portfolioUuids)
      transformedPayload['account_ids'] = portfolioUuids.join(',')
    if (instrumentIds)
      transformedPayload['instrument_ids'] = instrumentIds.join(',')
    if (type) transformedPayload['type'] = type
    if (dateFrom) transformedPayload['date_from'] = dateFrom
    if (dateTo) transformedPayload['date_to'] = dateTo
    if (sort) transformedPayload['sort'] = sort
    if (pageSize) transformedPayload['page_size'] = `${pageSize}`
    if (page) transformedPayload['page'] = `${page}`
    const params = new URLSearchParams(transformedPayload)
    const api = `${NEXT_PUBLIC_API_BASE_URL}broker-sync/portfolios/get_transactions/?${params}`
    transactionData = await fetch(api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        apikey: `${APIKEY}`,
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (error) {
    throw 'There was an error getting transactions in My Stocks'
    return error
  }
  if (transactionData) {
    const transactionsResult = await transactionData.json()
    return transactionsResult
  }
}

export {
  // getWatchedInstrumentsApi,
  fetchRealtimeQuotes,
  getMyStocksPortfoliosApi,
  getMyStocksPlaceholderHoldingsApi,
  createMyStocksAccountApi,
  updateMyStocksAccountApi,
  deleteMyStocksInstitutionAccountApi,
  deleteMyStocksManualAccountApi,
  uploadMyStocksCSVApi,
  getMyStocksTransactionsApi,
  getMyStocksAccountsApi,
}
