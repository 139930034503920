import {gql} from '@apollo/client'

export const ADD_WATCH_MUTATION = gql(`
  mutation AddWatch($input: WatchInput!) {
    addWatch(input: $input)
  }
`)

export const DELETE_WATCH_MUTATION = gql(`
  mutation DeleteWatch($input: WatchInput!) {
    deleteWatch(input: $input)
  }
`)

export const ADD_WATCHES_MUTATION = gql(`
  mutation AddWatches($input: [WatchInput!]!) {
    addWatches(input: $input)
  }
`)

export const DELETE_WATCHES_MUTATION = gql(`
  mutation DeleteWatches($input: [WatchInput!]!) {
    deleteWatches(input: $input)
  }
`)

export const CREATE_TRANSACTION_MUTATION = gql(`
  mutation CreateMyStocksTransaction($input: [CreateMyStocksTransactionInput!]!) {
    createMyStocksTransaction(input: $input){
      accountId
      amount
      company
      id
      shares
      symbol
      price
      instrumentId
    }
  }
`)

export const UPDATE_TRANSACTION_MUTATION = gql(`
  mutation UpdateMyStocksTransaction($input: UpdateMyStocksTransactionInput!) {
    updateMyStocksTransaction(input: $input){
      instrumentId
      symbol
      transactionDate
      transactionType
      shares
      price
    }
  }
`)

export const DELETE_TRANSACTION_MUTATION = gql(`
  mutation DeleteMyStocksTransaction($input: DeleteMyStocksTransactionInput!) {
    deleteMyStocksTransaction(input: $input)
  }
`)

export const ADD_WATCH_AND_TRANSACTION_MUTATION = gql(`
  mutation AddWatchAndCreateTransaction($watchInput: WatchInput!, $transactionInput: [CreateMyStocksTransactionInput!]!) {
      addWatch(input: $watchInput)
      createMyStocksTransaction(input: $transactionInput) {
        accountId
        amount
        company
        id
        shares
        symbol
        price
        instrumentId
      }
    }
  `)
