import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '~data/client/store'
import extraReducers from '~app/(main)/my-stocks/utils/extraReducers'
import {
  PortfolioUuidType,
  MyStocksFormType,
  Portfolio,
  Holding,
  Transaction,
  PortfolioSummary,
  MyPortfolioType,
  MissingTransactions,
  PortfolioTableTotalData,
  WatchlistHolding,
  TransactionLookup,
} from '~types/my-stocks'
import {StatusState} from '~types/utils'
import {createSelector} from '@reduxjs/toolkit'
import {
  ALTERNATE_ACTIVE_PORTFOLIO_VIEW,
  MY_STOCKS_REDUCER_KEY,
  STATUS_STATES,
} from '~data/constants'

// TODO: use PortfolioUuidType instead of string

// TODO: Move this out into its own state file
export type MyStocksState = {
  // The below are new thunk states
  getHoldingsStatus: StatusState
  getWatchlistHoldingsStatus: StatusState
  getMissingTransactionsStatus: StatusState
  getPortfolioStatus: StatusState
  getTransactionStatus: StatusState

  invalidHoldings: PortfolioUuidType[]
  invalidWatchlistHoldings: PortfolioUuidType[]

  // Track when initial calls have already been ran (app state)
  initialAccountsLoad: boolean
  initialHoldingsLoad: boolean
  initialTransactionsLoad: boolean
  batchHoldingsLoading: boolean

  // main data objects that have been transformed
  // and cleaned from their respective api response data (domain data)
  portfolios: Portfolio[] | null
  portfolioTotalData: PortfolioTableTotalData | null
  holdings: Holding[] | null
  watchlistHoldings: WatchlistHolding[] | null

  holdingsLookup: {[key: string]: Holding[]}
  watchlistHoldingsLookup: {[key: string]: WatchlistHolding[]}

  // Lookup transactions based on portfolio uuid
  transactionsLookup: {[key: string]: TransactionLookup}

  // Every time the 'Show More' button on transactions is clicked, this should update
  fetchMoreTransactions: boolean

  // The active form in the modal (ui state)
  activeForm: MyStocksFormType | null

  // The active portfolio the user is viewing
  // using the portfolio dropdown
  // When user is viewing all stocks, this becomes 'all-stocks' (app state)
  activePortfolioUuid: PortfolioUuidType | null

  // Used when user is actively modifying a portfolio name
  activeEditPortfolioUuid: PortfolioUuidType | null

  //Lookup portfolio summaries
  portfolioSummaryLookup: {[key: string]: PortfolioSummary}

  // Used when user is actively modifying a holding
  // in one of the modal forms (app state)
  activeHoldingId: string | null

  // Used when the user is attempting to
  // modify a single transaction or delete a single transaction (app state)
  activeTransactionId: number | null

  // The overall summary of the active portfolio (domain data)
  activePortfolioSummary: PortfolioSummary | null

  // When the ellipsis is clicked on the transactions table, this is the active transaction
  activeTransactionRow: number | null

  // Used for the bulk purchase transactions modal (domain data)
  // activePortfolioMissingTransactions displays the missing transactions for the active portfolio
  activePortfolioMissingTransactions: MissingTransactions[] | null

  // allPortfoliosMissingTransactions displays the missing transactions for all portfolios when on All Stocks
  allPortfoliosMissingTransactions: MissingTransactions[] | null

  transactionsTotalPages: number | null

  subnavPortfolios: MyPortfolioType[]

  // Preserve the Analyst Insights coverage tab when parallel-route modal is
  // opened.
  activeCoverageTab: string | null
}

const initialState: MyStocksState = {
  getHoldingsStatus: STATUS_STATES.INITIAL,
  getWatchlistHoldingsStatus: STATUS_STATES.INITIAL,
  getMissingTransactionsStatus: STATUS_STATES.INITIAL,
  getPortfolioStatus: STATUS_STATES.INITIAL,
  getTransactionStatus: STATUS_STATES.INITIAL,
  invalidHoldings: [],
  invalidWatchlistHoldings: [],
  initialAccountsLoad: false,
  initialHoldingsLoad: false,
  initialTransactionsLoad: false,
  batchHoldingsLoading: true,
  activeForm: null,
  // TODO: Phase out portfolios and memoize with createSelector instead
  portfolios: null,
  portfolioTotalData: null,
  holdings: null,
  // TODO: Phase out holdings and memoize with createSelector instead
  watchlistHoldings: null,
  holdingsLookup: {},
  watchlistHoldingsLookup: {},
  activePortfolioUuid: null,
  activeEditPortfolioUuid: null,
  activeHoldingId: null,
  activeTransactionId: null,
  activeTransactionRow: null,
  // TODO: Phase out transactions and memoize with createSelector instead
  transactionsLookup: {},
  fetchMoreTransactions: false,
  activePortfolioSummary: null,
  activePortfolioMissingTransactions: null,
  allPortfoliosMissingTransactions: null,
  portfolioSummaryLookup: {},
  transactionsTotalPages: 1,
  subnavPortfolios: [],
  activeCoverageTab: null,
}

const myStocksSlice = createSlice({
  name: MY_STOCKS_REDUCER_KEY,
  initialState,
  reducers: {
    // NOTE: invalidateBothHoldings will eventually become invalidateHoldings
    // and current invalidateHoldings will become invalidatePortfolioHoldings
    // to differentiate between the the holdings in My Stocks and the holdings
    // that are used for watchlist
    // TODO: Need to refactor this to invalidatePortfolioHoldings
    invalidateBothHoldings: (
      state,
      action: PayloadAction<PortfolioUuidType[]>,
    ) => {
      state.invalidHoldings = [...state.invalidHoldings, ...action.payload]
      state.invalidWatchlistHoldings = [
        ...state.invalidWatchlistHoldings,
        ...action.payload,
      ]
    },
    removeHolding: (
      state,
      action: PayloadAction<{holdingId: string | null}>,
    ) => {
      const {holdingId} = action.payload
      if (holdingId === null) return
      state.holdings = state.holdings
        ? state.holdings.filter(
            (holding) => holding && holding.id !== holdingId,
          )
        : []
      state.watchlistHoldings = state.watchlistHoldings
        ? state.watchlistHoldings.filter(
            (holding) => holding && holding.id !== holdingId,
          )
        : []
    },
    // TODO: Start calling these PortfolioHoldings
    invalidateHoldings: (state, action: PayloadAction<PortfolioUuidType[]>) => {
      state.invalidHoldings = [...state.invalidHoldings, ...action.payload]
    },
    validateHoldings: (state, action: PayloadAction<PortfolioUuidType>) => {
      state.invalidHoldings = state.invalidHoldings.filter(
        (uuid) => uuid !== action.payload,
      )
    },
    invalidateWatchlistHoldings: (
      state,
      action: PayloadAction<PortfolioUuidType[]>,
    ) => {
      state.invalidWatchlistHoldings = [
        ...state.invalidWatchlistHoldings,
        ...action.payload,
      ]
    },
    validateWatchlistHoldings: (
      state,
      action: PayloadAction<PortfolioUuidType>,
    ) => {
      state.invalidWatchlistHoldings = state.invalidWatchlistHoldings.filter(
        (uuid) => uuid !== action.payload,
      )
    },
    setInitialAccountsLoad: (state) => {
      state.initialAccountsLoad = true
    },
    setInitialHoldingsLoad: (
      state,
      action: PayloadAction<{holdingsStatus: boolean}>,
    ) => {
      state.initialHoldingsLoad = action.payload.holdingsStatus
    },
    setInitialTransactionsLoad: (state) => {
      state.initialTransactionsLoad = true
    },
    setBatchHoldingsLoading: (
      state,
      action: PayloadAction<{isLoading: boolean}>,
    ) => {
      state.batchHoldingsLoading = action.payload.isLoading
    },
    updateActiveForm: (
      state,
      action: PayloadAction<{
        activeForm: MyStocksFormType | null
      }>,
    ) => {
      state.activeForm = action.payload.activeForm
    },
    updateActivePortfolioUuid: (
      state,
      action: PayloadAction<{portfolioUuid: string | null}>,
    ) => {
      state.activePortfolioUuid = action.payload.portfolioUuid
    },
    updateSubnavPortfolios: (
      state,
      action: PayloadAction<MyPortfolioType[]>,
    ) => {
      state.subnavPortfolios = action.payload
    },
    updateActiveEditPortfolioUuid: (
      state,
      action: PayloadAction<{portfolioUuid: string | null}>,
    ) => {
      state.activeEditPortfolioUuid = action.payload.portfolioUuid
    },
    updateActiveHoldingId: (
      state,
      action: PayloadAction<{holdingId: string | null}>,
    ) => {
      state.activeHoldingId = action.payload.holdingId
    },
    updateActiveTransactionId: (
      state,
      action: PayloadAction<{transactionId: number | null}>,
    ) => {
      state.activeTransactionId = action.payload.transactionId
    },
    updateActiveTransactionRow: (
      state,
      action: PayloadAction<{transactionRowId: number | null}>,
    ) => {
      state.activeTransactionRow = action.payload.transactionRowId
    },
    updatePortfolios: (
      state,
      action: PayloadAction<{portfolios: Portfolio[]}>,
    ) => {
      state.portfolios = action.payload.portfolios
    },
    updatePortfolioSummariesInLookup: (
      state,
      action: PayloadAction<{
        portfolioUuidKey: string
        portfolioSummary: PortfolioSummary
      }>,
    ) => {
      state.portfolioSummaryLookup[action.payload.portfolioUuidKey] =
        action.payload.portfolioSummary
    },
    updatePortfolioTotalData: (
      state,
      action: PayloadAction<{portfolioTotalData: PortfolioTableTotalData}>,
    ) => {
      state.portfolioTotalData = action.payload.portfolioTotalData
    },
    updateHoldings: (state, action) => {
      state.holdings = action.payload.holdings
    },
    updateWatchlistHoldings: (
      state,
      action: PayloadAction<{watchlistHoldings: WatchlistHolding[]}>,
    ) => {
      state.watchlistHoldings = action.payload.watchlistHoldings
    },
    updateWatchlistHoldingsInLookup: (
      state,
      action: PayloadAction<{
        portfolioUuidKey: string
        watchlistHoldings: WatchlistHolding[]
      }>,
    ) => {
      state.watchlistHoldingsLookup[action.payload.portfolioUuidKey] =
        action.payload.watchlistHoldings
    },
    updateHoldingsInLookup: (
      state,
      action: PayloadAction<{portfolioUuidKey: string; holdings: Holding[]}>,
    ) => {
      state.holdingsLookup = Object.assign(state.holdingsLookup, {
        [action.payload.portfolioUuidKey]: action.payload.holdings,
      })
    },
    updateFetchMoreTransactions: (state, action: PayloadAction<boolean>) => {
      state.fetchMoreTransactions = action.payload
    },
    updateTransactionsLookup: (
      state,
      action: PayloadAction<{
        transactionsLookup: Transaction[]
        transactionUuid: string
        transactionsTotalPages: number
        hasTransactions: boolean | null // Determines if the user has transactions; not including placeholder transactions
        currentPage: number
        loadMoreTransactions: boolean
      }>,
    ) => {
      state.transactionsLookup[action.payload.transactionUuid] = {
        transactions:
          state.transactionsLookup !== null &&
          action.payload.loadMoreTransactions
            ? state.transactionsLookup[
                action.payload.transactionUuid
              ].transactions.concat(action.payload.transactionsLookup)
            : action.payload.transactionsLookup,
        hasTransactions: action.payload.hasTransactions,
        loadMoreTransactions: action.payload.loadMoreTransactions,
        currentPage: action.payload.currentPage,
        transactionsTotalPages: action.payload.transactionsTotalPages,
      }
    },
    updateActivePortfolioSummary: (
      state,
      action: PayloadAction<{activePortfolioSummary: PortfolioSummary}>,
    ) => {
      state.activePortfolioSummary = action.payload.activePortfolioSummary
    },
    updateAllMissingTransactions: (
      state,
      action: PayloadAction<{
        portfolioMissingTransactions: MissingTransactions[] | null
      }>,
    ) => {
      // This updates ALL portfolios missing transactions regardless of portfolio
      state.allPortfoliosMissingTransactions =
        action.payload.portfolioMissingTransactions
    },
    updateMissingPortfolioTransactions: (
      state,
      action: PayloadAction<{
        portfolioMissingTransactions: MissingTransactions[] | null
      }>,
    ) => {
      // This updates the missing transactions for the active portfolio
      state.activePortfolioMissingTransactions =
        action.payload.portfolioMissingTransactions
    },
    updateActiveCoverageTab: (
      state,
      action: PayloadAction<{tab: string | null}>,
    ) => {
      state.activeCoverageTab = action.payload.tab
    },
  },
  extraReducers,
})

const getActiveFormType = (state: RootState) => state.myStocks.activeForm
const getActivePortfolioUuid = (state: RootState) =>
  state.myStocks.activePortfolioUuid
function getActivePortfolio(state: RootState) {
  return (
    state.myStocks.portfolios?.find(
      (portfolio: Portfolio) =>
        portfolio?.uuid === state.myStocks.activePortfolioUuid,
    ) ?? null
  )
}
function getActiveEditPortfolio(state: RootState) {
  return (
    state.myStocks.portfolios?.find(
      (portfolio: Portfolio) =>
        portfolio?.uuid === state.myStocks.activeEditPortfolioUuid,
    ) ?? null
  )
}
function getActivePortfolioType(state: RootState) {
  return (
    state.myStocks.portfolios?.find(
      (portfolio: Portfolio) =>
        portfolio?.uuid === state.myStocks.activePortfolioUuid,
    )?.type ?? null
  )
}
function getActiveHolding(state: RootState) {
  return state.myStocks.holdings?.find((holding: Holding) => {
    return holding.id === state.myStocks.activeHoldingId
  })
}
function getActiveTransaction(state: RootState) {
  const activePortfolioUuid = getActivePortfolioUuid(state)
  if (activePortfolioUuid) {
    return state.myStocks.transactionsLookup[
      activePortfolioUuid as string
    ]?.transactions?.find((transaction: Transaction) => {
      return transaction?.id === state.myStocks.activeTransactionId
    })
  }
}
function getTransactionsForActiveHolding(
  state: RootState,
  instrumentId: number,
  portfolioUuids?: string[],
) {
  const activePortfolioUuid = getActivePortfolioUuid(state)
  if (!activePortfolioUuid) return null
  return state.myStocks.transactionsLookup[
    activePortfolioUuid
  ].transactions?.filter((transaction: Transaction) => {
    if (portfolioUuids && portfolioUuids.length > 0) {
      return (
        portfolioUuids.includes(transaction.accountUuid) &&
        transaction.instrumentId === instrumentId
      )
    } else {
      return (
        transaction.accountUuid === state.myStocks.activePortfolioUuid &&
        transaction.instrumentId === instrumentId
      )
    }
  })
}
function getHoldingByInstrumentIdAndActivePortfolio(
  state: RootState,
  instrumentId: number,
) {
  const holdingResult = state.myStocks.holdings?.find((holding) => {
    // If viewing All Stocks, then find the portfolio that matches the instrumentId
    // If not under All Stocks, then the current active portfolio Uuid is used

    if (holding.instrumentId === instrumentId) return true
    return false
  })
  return holdingResult
}
function getHoldingsForActivePortfolio(state: RootState) {
  if (!state.myStocks.activePortfolioUuid) {
    return state.myStocks.holdings
  }
  return state.myStocks.holdings?.filter((holding) => {
    const portfolio = holding.portfolios?.find(
      (portfolio) => portfolio?.uuid === state.myStocks.activePortfolioUuid,
    )
    if (portfolio) return true
    return false
  })
}
function getActivePortfolioSummary(state: RootState) {
  return state.myStocks.activePortfolioSummary || null
}
function getPortfolioByUuid(state: RootState, uuid: string) {
  return state.myStocks.portfolios?.find(
    (portfolio) => portfolio?.uuid === uuid,
  )
}

// Get all portfolios containing the active holding
function getPortfoliosWithActiveHolding(state: RootState): MyPortfolioType[] {
  const activeHoldingInstrumentId = getActiveHolding(state)?.instrumentId
  const portfoliosWithHolding =
    state.myStocks.holdings?.reduce((acc, holding) => {
      if (holding.instrumentId === activeHoldingInstrumentId) {
        holding.portfolios?.forEach((portfolio) => {
          acc.push(portfolio)
        })
      }
      return acc
    }, [] as MyPortfolioType[]) || []
  return portfoliosWithHolding
}

function getMissingTransactionsByPortfolio(
  state: RootState,
  portfolioUuid?: string | null,
) {
  let missingTransactions: MissingTransactions[] | [] = []
  const allMissingTransactions =
    state.myStocks.allPortfoliosMissingTransactions || []
  const currentActivePortfolioUuid =
    state.myStocks.activePortfolioUuid || portfolioUuid
  const activePortfolio = getActivePortfolio(state)
  const activePortfolioName = activePortfolio?.name
    ? activePortfolio.name
    : 'All Stocks'
  if (
    currentActivePortfolioUuid !== 'all-stocks' &&
    currentActivePortfolioUuid !== null &&
    allMissingTransactions !== null
  ) {
    missingTransactions = allMissingTransactions?.filter(
      (holdings: MissingTransactions) => {
        return holdings?.accountUuid === currentActivePortfolioUuid
      },
    )
    missingTransactions = missingTransactions.map((item) => {
      return {...item, portfolio: activePortfolioName}
    })
    return missingTransactions
  } else if (allMissingTransactions) {
    return allMissingTransactions
  }
}
function selectUuid(state: RootState): string | null {
  if (!state.route?.currentRoute) return null
  const route = state.route?.currentRoute
  const baseUrl = window.location.origin
  const url = new URL(route, baseUrl)
  return url.searchParams.get('uuid')
}
function selectUuidQueryString(state: RootState): string {
  const uuid = selectUuid(state)
  if (uuid) {
    const params = new URLSearchParams()
    params.set('uuid', uuid)
    return params.toString()
  }
  return ''
}

export const selectActivePortfolioUuid = (state: RootState) =>
  state.myStocks.activePortfolioUuid

export const selectHoldingsLookup = (state: RootState) =>
  state.myStocks.holdingsLookup

export const selectActiveHoldings = createSelector(
  [selectActivePortfolioUuid, selectHoldingsLookup],
  (activePortfolioUuid, holdingsLookup) => {
    const portfolioUuid = activePortfolioUuid || ''
    if (activePortfolioUuid) {
      return holdingsLookup[portfolioUuid] || []
    }
    return []
  },
)

export const selectAllStocksHoldings = createSelector(
  [selectHoldingsLookup],
  (selectHoldingsLookup) => {
    return selectHoldingsLookup[ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks]
  },
)

const getActiveCoverageTab = (state: RootState) =>
  state.myStocks.activeCoverageTab

export const {
  invalidateBothHoldings,
  invalidateHoldings,
  validateHoldings,
  invalidateWatchlistHoldings,
  validateWatchlistHoldings,
  removeHolding,
  setInitialAccountsLoad,
  setInitialHoldingsLoad,
  setInitialTransactionsLoad,
  setBatchHoldingsLoading,
  updateActiveForm,
  updatePortfolios,
  updatePortfolioTotalData,
  updateHoldings,
  updateWatchlistHoldings,
  updateHoldingsInLookup,
  updateWatchlistHoldingsInLookup,
  updateActivePortfolioUuid,
  updateActiveEditPortfolioUuid,
  updateActiveHoldingId,
  updateActiveTransactionId,
  updateActiveTransactionRow,
  updateTransactionsLookup,
  updateFetchMoreTransactions,
  updateActivePortfolioSummary,
  updateAllMissingTransactions,
  updateMissingPortfolioTransactions,
  updatePortfolioSummariesInLookup,
  updateSubnavPortfolios,
  updateActiveCoverageTab,
} = myStocksSlice.actions
export {
  getActiveFormType,
  getActivePortfolioUuid,
  getActivePortfolio,
  getActiveEditPortfolio,
  getActiveHolding,
  getActiveTransaction,
  getTransactionsForActiveHolding,
  getHoldingByInstrumentIdAndActivePortfolio,
  getHoldingsForActivePortfolio,
  getActivePortfolioSummary,
  getPortfoliosWithActiveHolding,
  getPortfolioByUuid,
  getActivePortfolioType,
  getMissingTransactionsByPortfolio,
  selectUuid,
  selectUuidQueryString,
  getActiveCoverageTab,
}
export default myStocksSlice.reducer
